import { Button, PasswordInput } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { gql } from '@/gql'
import { getErrorMessage } from '@/utils/errors'
import { toastError, toastSuccess } from '@/utils/toast'
import { useMutation } from '@apollo/client'
import { T, useTranslate } from '@tolgee/react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

const RESET_PASSWORD = gql(`
  mutation resetPassword($token: String!, $password: String!) {
    password: changePassword(token: $token, password: $password) {
      id
    }
  }
`)

export const ResetPassword: React.FC = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const token = location.search.split('?')[1]

  const [resetPassword] = useMutation(RESET_PASSWORD)

  const validationSchema = yup.object().shape({
    confirmPassword: yup
      .string()
      .required(t('form.inputs.errors.password'))
      .min(8),
    password: yup.string().required(t('form.inputs.errors.password')).min(8)
  })

  const formik = useFormik({
    initialValues: {
      confirmPassword: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      if (values.password !== values.confirmPassword) {
        formikHelpers.setFieldError(
          'confirmPassword',
          t('form.inputs.errors.passwordsNotMatch')
        )
        return
      }
      try {
        await resetPassword({
          variables: {
            token: token,
            password: values.password
          }
        })
        toastSuccess(t('components.reset.success'))
        navigate('/auth/login')
      } catch (error) {
        console.error(error)
        toastError(getErrorMessage(error))
      }
    }
  })

  const content = (
    <div className="verify-email-popup-content max-md:w-full text-center">
      <h1 className="text-lg font-medium">
        {t('components.resetPassword.title')}
      </h1>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col mt-2 mb-1 max-md:mb-2">
          <PasswordInput
            name="password"
            formik={formik}
            intlKey="resetPassword"
            required
          />
        </div>

        <div className="flex flex-col mt-2 mb-1 max-md:mb-2">
          <PasswordInput
            name="confirmPassword"
            formik={formik}
            intlKey="confirmResetPassword"
            required
          />
        </div>

        <div className="flex justify-center items-center mt-3">
          <Button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <T keyName="components.resetPassword.btn" />
          </Button>
        </div>
      </form>
    </div>
  )

  return (
    <PopupSkeleton showClose={true} onClose={() => navigate('/')}>
      <div className="w-[480px] h-full p-10 max-md:p-4 max-md:w-full">
        {content}
      </div>
    </PopupSkeleton>
  )
}
