import { Button, TextInput } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { gql } from '@/gql'
import { getErrorMessage } from '@/utils/errors'
import { toastError } from '@/utils/toast'
import { useMutation } from '@apollo/client'
import { T, useTranslate } from '@tolgee/react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import invariant from 'tiny-invariant'
import * as yup from 'yup'

const FORGOT_PASSWORD = gql(`
  mutation forgotPassword($email: String!) {
    forgot: forgotPassword(email: $email)
  }
`)

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const [forgotPassword] = useMutation(FORGOT_PASSWORD)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('form.inputs.errors.email'))
      .required(t('form.inputs.errors.email'))
  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      console.log('submit')
      invariant(values.email, 'email is required')
      try {
        await forgotPassword({
          variables: {
            email: values.email
          }
        }).then(() => {
          navigate('/auth/forgot/success')
        })
      } catch (error) {
        toastError(getErrorMessage(error))
        formikHelpers.setSubmitting(false)
        formikHelpers.resetForm()
      }
    }
  })

  const content = (
    <div className="verify-email-popup-content max-md:w-full text-center">
      <h1 className="text-lg font-medium">{t('components.forgot.title')}</h1>

      <p className="flex text-gray-100 justify-center items-center m-1">
        {t('components.forgot.info')}
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col mt-2 mb-1 max-md:mb-2">
          <TextInput
            name="email"
            formik={formik}
            required={false}
            label={t('form.inputs.labels.forgotEmail')}
          />
        </div>

        <div className="flex justify-center items-center mt-3">
          <Button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <T keyName="components.forgot.button" />
          </Button>
        </div>
      </form>
    </div>
  )

  return (
    <PopupSkeleton showClose={true} onClose={() => navigate('/')}>
      <div className="w-[480px] h-full p-10 max-md:p-4 max-md:w-full">
        {content}
      </div>
    </PopupSkeleton>
  )
}
